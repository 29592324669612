import React, { Component } from 'react';
import './Stopwatch.css';

class Stopwatch extends Component {
    state = {
        counter : 0,
        timeIsRunning:false,
        btnText: "Start"
    }

    onStart = () => {
        if(!this.state.timeIsRunning){
            this.intervalId = setInterval(()=>{
                this.setState({
                    counter: this.state.counter + 100,
                })
            },100) 

            this.setState({
                timeIsRunning: true,
                btnText : "Stop"
            })
        }else { 
            clearInterval(this.intervalId);
         
            this.setState ({
                timeIsRunning: false,
                btnText : "Start"
            })
        }
    }

    onReset = () => {
        clearInterval(this.intervalId);
        this.setState ({
            counter: 0,
            btnText : "Start",
            timeIsRunning:false
        })
    } 
        
    render() {
        return (
            <div className="stopwatch">
                <h3>Stopwatch: {this.state.counter}</h3>
                <button onClick={this.onStart}>{this.state.btnText}</button>
                <button onClick={this.onReset}>Reset</button>
            </div>
        );
    }
}

export default Stopwatch;